import React from "react";

const Footer = () => {
  return (
    <section
      data-bs-version="5.1"
      className="footer6 cid-sysv1ufJOA"
      //once="footers"
      id="footer6-8"
    >
      <div className="container">
        <div className="row content mbr-white">
          <div className="col-12 col-md-3 mbr-fonts-style display-7">
            <h5 className="mbr-section-subtitle mbr-fonts-style mb-2 display-7">
              <strong>Address</strong>
            </h5>
            <p className="mbr-text mbr-fonts-style display-7">
              40 Somhlolo Rd
              <br />
              Mbabane
              <br />
              Eswatini
            </p>{" "}
            <br />
            <h5 className="mbr-section-subtitle mbr-fonts-style mb-2 mt-4 display-7">
              <strong>Contacts</strong>
            </h5>
            <p className="mbr-text mbr-fonts-style mb-4 display-7">
              Email:{" "}
              <a href="mailto:dev@bpdteam.com" className="text-primary">
                dev@bpdteam.com
              </a>
              <br />
              Phone:{" "}
              <a href="tel:79360602" className="text-primary">
                79360602
              </a>
              <br />
            </p>
          </div>
          <div className="col-12 col-md-3 mbr-fonts-style display-7">
            <h5 className="mbr-section-subtitle mbr-fonts-style mb-2 display-7">
              <strong>Links</strong>
            </h5>
            <ul className="list mbr-fonts-style mb-4 display-4">
              <li className="mbr-text item-wrap">
                <a href="https://bpdteam.com" className="text-primary">
                  BPD Team
                </a>
              </li>
              <li className="mbr-text item-wrap">
                <a
                  href="https://fetchmykid.bpdteam.com"
                  className="text-primary"
                >
                  FetchMyKid
                </a>
              </li>
            </ul>
            <h5 className="mbr-section-subtitle mbr-fonts-style mb-2 mt-5 display-7">
              <strong>Feedback</strong>
            </h5>
            <p className="mbr-text mbr-fonts-style mb-4 display-7">
              Please send us your ideas, bug reports, suggestions! Any feedback
              would be appreciated.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <div className="google-map">
              <div>
                <iframe
                title="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3576.2301794070513!2d31.130971049890356!3d-26.319045083305372!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ee8cfa90238d57d%3A0xe895909131dd32c4!2sBPD%20Team!5e0!3m2!1sen!2s!4v1680514461634!5m2!1sen!2s"
                  
                  style={{border:0, width:"500px",
                  height:"400px"}}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="social-list align-left">
              <div className="soc-item">
                <a
                  href="https://www.facebook.com/BPD-Team-106900058269741/"
                  //target="_blank"
                >
                  <span
                    className="mbr-iconfont mbr-iconfont-social socicon-facebook socicon"
                    style={{
                      color: "rgb(230, 198, 59)",
                      fill: "rgb(230, 198, 59)",
                    }}
                  ></span>
                </a>
              </div>
              <div className="soc-item">
                <a
                  href="https://www.linkedin.com/company/bpd-investment"
                  //target="_blank"
                >
                  <span
                    className="mbr-iconfont mbr-iconfont-social socicon-linkedin socicon"
                    style={{
                      color: "rgb(230, 198, 59)",
                      fill: "rgb(230, 198, 59)",
                    }}
                  ></span>
                </a>
              </div>
              <div className="soc-item">
                <a href="https://chatwith.io/s/bpd-team">
                  <span
                    className="mbr-iconfont mbr-iconfont-social socicon-whatsapp socicon"
                    style={{
                      color: "rgb(230, 198, 59)",
                      fill: "rgb(230, 198, 59)",
                    }}
                  ></span>
                </a>
              </div>
              <div className="soc-item">
                <a href="https://instagram.com/bpd.team">
                  <span
                    className="mbr-iconfont mbr-iconfont-social socicon-instagram socicon"
                    style={{
                      color: "rgb(230, 198, 59)",
                      fill: "rgb(230, 198, 59)",
                    }}
                  ></span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-lower">
          <div className="media-container-row">
            <div className="col-sm-12">
              <hr />
            </div>
          </div>
          <div className="col-sm-12 copyright pl-0">
            <p className="mbr-text mbr-fonts-style mbr-white display-7">
              © Copyright {new Date().getFullYear()} BPD Team- All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Footer;
