import React from "react";

const Clients = () => {
  return (
    <section
      data-bs-version="5.1"
    className="clients1 cid-sysuNmt5Ba"
      id="clients1-6"
    >
      <div className="images-container container">
        <div className="mbr-section-head">
          <h3 className="mbr-section-title mbr-fonts-style align-center mb-0 display-2">
            <strong>Our Clients</strong>
          </h3>

          <p className="mbr-text mbr-fonts-style align-center mt-2 display-7">
            Clients that we are currently working with
          </p>
        </div>
        <div className="row justify-content-center mt-4">
          <div className="col-md-3 card">
            <img src="assets/images/micro.png" alt="" style={{width: "90px"}} />
          </div>
          <div className="col-md-3 card">
            <img src="assets/images/logo1-240x155.png" alt="" />
          </div>
          <div className="col-md-3 card">
            <img src="assets/images/logo-188x107.png" alt="" />
          </div>
          <div className="col-md-3 card">
            <img src="assets/images/alphsz.png" alt="" style={{marginTop: "15px"}} />
          </div>
          <div className="col-md-3 card">
            <img src="assets/images/logo1-1-240x155.png" alt="" />
          </div>
          <div className="col-md-3 card">
            <img src="assets/images/connectit-240x171.png" alt="" />
          </div>
          <div className="col-md-3 card">
            <img
              src="assets/images/epdawu-removebg-preview-240x183.png"
              alt=""
            />
          </div>
          <div className="col-md-3 card">
            <img
              src="assets/images/siyaphila-natural-medicines-123x62.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Clients;
