import React from "react";

const Services = () => {
  return (
    <section
      data-bs-version="5.1"
      className="features13 cid-sCvr09W8gC"
      id="features14-b"
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3 className="mbr-section-title align-center mb-4 mbr-fonts-style display-2">
              <strong>What We Provide</strong>
            </h3>
          </div>
          <div className="card col-12 col-md-4 col-lg-2 p-3">
            <div className="card-wrapper">
              <div className="card-box align-center">
                <span
                  className="mbr-iconfont mbrib-globe-2"
                  style={{color: "rgb(230, 198, 59)", fill: "rgb(230, 198, 59)"}}
                ></span>
                <h4 className="card-title align-center mbr-black mbr-fonts-style display-7">
                  <strong>Domain Name Registration</strong>
                </h4>
              </div>
            </div>
          </div>
          <div className="card p-3 col-12 col-md-4 col-lg-2">
            <div className="card-wrapper">
              <div className="card-box align-center">
                <span
                  className="mbr-iconfont socicon-mailru socicon"
                  style={{color: "rgb(230, 198, 59)", fill: "rgb(230, 198, 59)"}}
                ></span>
                <h4 className="card-title align-center mbr-black mbr-fonts-style display-7">
                  <strong>Corporate Emails</strong>
                </h4>
              </div>
            </div>
          </div>
          <div className="card p-3 col-12 col-md-4 col-lg-2">
            <div className="card-wrapper">
              <div className="card-box align-center">
                <span
                  className="mbr-iconfont mbrib-website-theme"
                  style={{color: "rgb(230, 198, 59)", fill: "rgb(230, 198, 59)"}}
                ></span>
                <h4 className="card-title align-center mbr-black mbr-fonts-style display-7">
                  <strong>Website Design</strong>
                </h4>
              </div>
            </div>
          </div>
          <div className="card p-3 col-12 col-md-4 col-lg-2">
            <div className="card-wrapper">
              <div className="card-box align-center">
                <span
                  className="mbr-iconfont mbrib-sites"
                  style={{color: "rgb(230, 198, 59)", fill: "rgb(230, 198, 59)"}}
                ></span>
                <h4 className="card-title align-center mbr-black mbr-fonts-style display-7">
                  <strong>Website Development</strong>
                </h4>
              </div>
            </div>
          </div>
          <div className="card p-3 col-12 col-md-4 col-lg-2">
            <div className="card-wrapper">
              <div className="card-box align-center">
                <span
                  className="mbr-iconfont mbri-drag-n-drop2"
                  style={{color: "rgb(230, 198, 59)", fill: "rgb(230, 198, 59)"}}
                ></span>
                <h4 className="card-title align-center mbr-black mbr-fonts-style display-7">
                  <strong>Website Maintenance</strong>
                </h4>
              </div>
            </div>
          </div>
          <div className="card p-3 col-12 col-md-4 col-lg-2">
            <div className="card-wrapper">
              <div className="card-box align-center">
                <span
                  className="mbr-iconfont mbrib-responsive"
                  style={{color: "rgb(230, 198, 59)", fill: "rgb(230, 198, 59)"}}
                ></span>
                <h4 className="card-title align-center mbr-black mbr-fonts-style display-7">
                  <strong>Desktop &amp; Mobile Apps</strong>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Services;
