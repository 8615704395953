import React from "react";

const YourNeedsCatered = () => {
  return (
    <section
      data-bs-version="5.1"
      className="features23 cid-systTexLR8"
      id="features23-4"
    >
      <div className="container">
        <div className="card-wrapper">
          <div className="card-box align-left">
            <h4 className="mbr-section-title mbr-fonts-style display-5">
              For all your website and software needs
            </h4>
          </div>
        </div>
        <div className="row justify-content-center content-row mt-4">
          <div className="card p-4 p-md-3 col-md-6 col-lg-4">
            <div className="card-box">
              <div className="title">
                <span className="num mbr-fonts-style display-1">
                  <strong>01.</strong>
                </span>
              </div>
              <h4 className="card-title mbr-fonts-style display-5">
                <strong>Design</strong>
              </h4>
              <p className="mbr-text card-text mbr-fonts-style display-7">
                and brand, it will be custom made for you.
              </p>
            </div>
          </div>
          <div className="card p-4 p-md-3 col-md-6 col-lg-4">
            <div className="card-box">
              <div className="title">
                <span className="num mbr-fonts-style display-1">
                  <strong>02.</strong>
                </span>
              </div>
              <h4 className="card-title mbr-fonts-style display-5">
                <strong>Development</strong>
              </h4>
              <p className="mbr-text card-text mbr-fonts-style display-7">
                using modern technology.
              </p>
            </div>
          </div>
          <div className="card p-4 p-md-3 col-md-6 col-lg-4">
            <div className="card-box">
              <div className="title">
                <span className="num mbr-fonts-style display-1">
                  <strong>03.</strong>
                </span>
              </div>
              <h4 className="card-title mbr-fonts-style display-5">
                <strong>Maintenance</strong>
                <br />
              </h4>
              <p className="mbr-text card-text mbr-fonts-style display-7">
                ensuring that it is always online and always accessible.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default YourNeedsCatered;
