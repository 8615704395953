import React, { useEffect, useState } from "react";
import axios from "axios";
 

const WhyUs = (props) => {
  const [client, setClients] = useState();
  useEffect(()=>{
    axios.get('http://104.248.53.221/api/clients-dev-count')
    .then(res => {
        setClients( res.data );
    })
    .catch(function (error) {
        console.log(error);
    })
  },[]) 
 

  return (
    <section
      data-bs-version="5.1"
      className="features1 cid-sysunLiuWC"
      id="features1-5"
    >
      <div className="container">
        <div className="row"></div>
        <div className="row">
          <div className="card col-12 col-md-6 col-lg-3">
            <div className="card-wrapper">
              <div className="card-box align-center">
                <div className="iconfont-wrapper">
                  <span
                    className="mbr-iconfont mbrib-smile-face"
                    style={{
                      color: "rgb(230, 198, 59)",
                      fill: "rgb(230, 198, 59)",
                    }}
                  ></span>
                </div>
                <h5 className="card-title mbr-fonts-style display-5">
                  <strong>{client}</strong>
                </h5>
                <p className="card-text mbr-fonts-style display-7">
                  Happy Clients
                </p>
              </div>
            </div>
          </div>
          <div className="card col-12 col-md-6 col-lg-3">
            <div className="card-wrapper">
              <div className="card-box align-center">
                <div className="iconfont-wrapper">
                  <span
                    className="mbr-iconfont mbri-delivery"
                    style={{
                      color: "rgb(230, 198, 59)",
                      fill: "rgb(230, 198, 59)",
                    }}
                  ></span>
                </div>
                <h5 className="card-title mbr-fonts-style display-5">
                  <strong>12</strong>
                </h5>
                <p className="card-text mbr-fonts-style display-7">
                  Projects Completed
                </p>
              </div>
            </div>
          </div>
          <div className="card col-12 col-md-6 col-lg-3">
            <div className="card-wrapper">
              <div className="card-box align-center">
                <div className="iconfont-wrapper">
                  <span
                    className="mbr-iconfont mbrib-timer"
                    style={{
                      color: "rgb(230, 198, 59)",
                      fill: "rgb(230, 198, 59)",
                    }}
                  ></span>
                </div>
                <h5 className="card-title mbr-fonts-style display-5">
                  <strong>5760</strong>
                </h5>
                <p className="card-text mbr-fonts-style display-7">
                  Hours Worked
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default WhyUs;
