import React from "react";

const Nav = () => {
  return (
    <section
      data-bs-version="5.1"
      className="menu menu2 cid-swqJRHJJVM"
      // once="menu"
      id="menu2-2"
    >
      <nav className="navbar navbar-dropdown navbar-fixed-top navbar-expand-lg">
        <div className="container">
          <div className="navbar-brand">
            <span className="navbar-logo">
              <a href="https://dev.bpdteam.com">
                <img
                  src="assets/images/16370583885588-99x46.png"
                  alt="BPD Dev"
                  style={{height:" 3.1rem"}}
                />
              </a>
            </span>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-bs-toggle="collapse"
            data-target="#navbarSupportedContent"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <div className="hamburger">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="navbar-buttons mbr-section-btn">
              <a
                className="btn btn-primary-outline display-4"
                href="https://bpdteam.com"
              >
                BPD Team
              </a>
            </div>
          </div>
        </div>
      </nav>
    </section>
  );
};
export default Nav;
