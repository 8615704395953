import React from "react";

const Banner = () => {
  return (
    <section
      data-bs-version="5.1"
     className="header1 cid-sCvkbwD3AW mbr-fullscreen mbr-parallax-background"
      id="header1-a"
    >
      <div
       className="mbr-overlay"
        //style="opacity: 0.6; background-color: rgb(0, 0, 0);"
        style={{opacity:'0.6', backgroundColor:' rgb(0, 0, 0)'}}
      ></div>

      <div className="align-center container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8">
            <h1 className="mbr-section-title mbr-fonts-style mb-3 display-1">
              <strong>BPD&nbsp;Development</strong>
              <br />
            </h1>

            <p className="mbr-text mbr-fonts-style display-7">
              <strong>Use Software to streamline&nbsp;your workflow</strong>
              <br />
              Give your company online presence and get more clients!
            </p>
            <div className="mbr-section-btn mt-3">
              <a className="btn btn-primary display-4" href="index.html#form5-9">
                Let Us Help You
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Banner;
