import React from "react";

const Pricing = () => {
  return (
    <section
      data-bs-version="5.1"
      className="pricing2 cid-sHyW3WmfwO"
      id="pricing2-c"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className=" col-md-6 align-center col-sm-12">
            <div className="plan">
              <div className="plan-header">
                <h6 className="plan-title mbr-fonts-style mb-3 display-5">
                  <strong>Basic Website</strong>
                </h6>
                <div className="plan-price">
                  <p className="price mbr-fonts-style m-0 display-1">
                    <strong>E3640</strong>
                  </p>
                  <p className="price-term mbr-fonts-style mb-3 display-7">
                    <strong>Once Off</strong>
                  </p>
                </div>
              </div>
              <div className="plan-body">
                <div className="plan-list mb-4">
                  <ul className="list-group mbr-fonts-style list-group-flush display-7">
                    <li className="list-group-item">Website Design</li>
                    <li className="list-group-item">Website Development</li>
                    <li className="list-group-item">Domain Name Registration (.com / .org / .co.za)</li>
                    <li className="list-group-item">Website Hosting (1 Month)</li>
                    <li className="list-group-item">Up to 5 Corporate Emails</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-md-6 align-center col-sm-12">
            <div className="plan">
              <div className="plan-header">
                <h6 className="plan-title mbr-fonts-style mb-3 display-5">
                  <strong>Corporate Emails</strong>
                </h6>
                <div className="plan-price">
                  <p className="price mbr-fonts-style m-0 display-1">
                    <strong>E540</strong>
                  </p>
                  <p className="price-term mbr-fonts-style mb-3 display-7">
                    <strong>Once Off</strong>
                  </p>
                </div>
              </div>
              <div className="plan-body">
                <div className="plan-list mb-4">
                  <ul className="list-group mbr-fonts-style list-group-flush display-7">
                    <li className="list-group-item">Domain Name Registration (.com / .org / .co.za)</li>
                    <li className="list-group-item">Email Hosting (1 Month)</li>
                    <li className="list-group-item">Up to 5 Corporate Emails</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-md-12 align-center col-sm-12">
            <div className="plan">
              <div className="plan-header">
                <h6 className="plan-title mbr-fonts-style mb-3 display-5">
                  <strong>Hosting</strong>
                </h6>
                <div className="plan-price">
                  <p className="price mbr-fonts-style m-0 display-1">
                    <strong>E100</strong>
                  </p>
                  <p className="price-term mbr-fonts-style mb-3 display-7">
                    <strong>Per Month</strong>
                  </p>
                </div>
              </div>
              <div className="plan-body">
                <div className="plan-list mb-4">
                  <ul className="list-group mbr-fonts-style list-group-flush display-7">
                    <li className="list-group-item">Unlimited Web Traffic</li>
                    <li className="list-group-item">2000MB Storage</li>
                    <li className="list-group-item">1 x MySQL Database</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-md-6 col-sm-12">
            <div className="plan">
              <div className="plan-header">
                <h6 className="plan-title mbr-fonts-style mb-3 display-5">
                  <strong>Domain Name Registration</strong>
                </h6>
                <div className="plan-price">
                  <p className="price mbr-fonts-style m-0 display-1">
                    <strong>E240</strong>
                  </p>
                  <p className="price-term mbr-fonts-style mb-3 display-7">
                    <strong>Per year</strong>
                  </p>
                </div>
              </div>
              <div className="plan-body">
                <div className="plan-list mb-4">
                  <ul className="list-group mbr-fonts-style list-group-flush display-7">
                    <li className="list-group-item">.com / .org / .co.za</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-md-6 col-sm-12">
            <div className="plan">
              <div className="plan-header">
                <h6 className="plan-title mbr-fonts-style mb-3 display-5">
                  <strong>Domain Name Registration</strong>
                </h6>
                <div className="plan-price">
                  <p className="price mbr-fonts-style m-0 display-1">
                    <strong>E500</strong>
                  </p>
                  <p className="price-term mbr-fonts-style mb-3 display-7">
                    <strong>Per year</strong>
                  </p>
                </div>
              </div>
              <div className="plan-body">
                <div className="plan-list mb-4">
                  <ul className="list-group mbr-fonts-style list-group-flush display-7">
                    <li className="list-group-item">.co.sz / .org.sz / .ac.sz</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Pricing;
