import React from "react";
import Banner from "../components/Banner";
import Clients from "../components/Clients";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import Pricing from "../components/Pricing";
import Services from "../components/Services";
//import WhatsApp from "../components/WhatsApp";
import WhyUs from "../components/WhyUs";
import YourNeedsCatered from "../components/YourNeedsCatered";

const Home = () => {
  return (
    <>
      <Nav />
      <Banner />
      <WhyUs />
      <Services />
      <YourNeedsCatered />
      <Clients />
      <Pricing />
      <Contact />
      {/* <WhatsApp /> */}
      <Footer />
    
    </>
  );
};
export default Home;
